import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/integralMall',
    name: '积分商城',
    component: () => import( /* webpackChunkName: "about" */ '../views/integralMallHome.vue'),
    meta: {
      public: true,
      title: "积分商城",
    }
  },
  {
    path: '/integralMallRecord',
    name: '兑换详情',
    component: () => import( /* webpackChunkName: "about" */ '../views/integralMallRecord.vue'),
    meta: {
      public: true,
      title: "兑换详情",
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      public: true,
      title: "订单详情",
    }
  },
  {
    path: '/order',
    name: '订单详情',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/OrderView.vue'),
    meta: {
      public: true,
      title: "订单详情",
    }
  },
  {
    path: '/orders',
    name: '订单详情',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/OrdersView.vue'),
    meta: {
      public: true,
      title: "订单详情",
    }
  },
  {
    path: '/QRCode',
    name: '识别二维码',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/QRCodeView.vue'),
    meta: {
      public: true,
      title: "订单详情",
    }
  }

]

const router = new VueRouter({
  mode:'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
