import Vue from 'vue'
import axiosVue from 'axios-vue'
import BaseUrl from './ApiUrls.js'
Vue.use(axiosVue)

// Vue.use(axiosVue, {
//   globalDefaults: {
//     baseURL: '/',
//     withCredentials: false,
//     headers: {
//       common: {
//       },
//       post: {
//         'Content-Type': 'application/json; charset=utf-8; application/x-www-form-urlencoded',
//         'Access-Control-Allow-Origin': '*'
//       },
//       // ...
//     },
//     // ...
//   },
//   default: {
//     baseURL: '/',
//     withCredentials: false,
//     headers: {
//       common: {
//       },
//       post: {
//         'Content-Type': 'application/json; charset=utf-8; application/x-www-form-urlencoded',
//         'Access-Control-Allow-Origin': '*'
//       },
//       // ...
//     },
//     // ...
//   },
// })

// Two ways for interceptors declaration
axiosVue.requestInterceptor = function(config) {
  return config
}
axiosVue.requestErrorInterceptor = function(error) {
  return Promise.reject(error)
}
axiosVue.responseInterceptor = function(response) {
  return response
}
axiosVue.responseErrorInterceptor = function(error) {
  return Promise.reject(error)
}

export default {

}
