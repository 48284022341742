import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './utils/cqtAxios.js'
import BaseUrl from './utils/ApiUrls.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// Vue.prototype.$http = axios
Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
